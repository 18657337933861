export const onRouteUpdate = function () {
  // Don't track while developing.
  if (process.env.NODE_ENV !== `production`) {
    return;
  }

  if (typeof window.fbq === `function`) {
    window.fbq('track', 'ViewContent');
  }

  if (window && typeof window.gtag === 'function') {
    window.gtag('config', 'G-CTC28QY3KG', {
      page_path: window.location.pathname,
    });
  }
};
